import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

$(document).ready(function(){
  if($('#index-circle-ani').length > 0){
    // 监听circle-ani 是否有class:is-inview
    var mo = new MutationObserver(function(records){
      if($(records[0].target).hasClass('is-inview')){
        var t1 = getRandomInt(30, 90);
        var r1 = getRandomInt(50, 90);
        var t2 = getRandomInt(30, 60);
        var r2 = getRandomInt(40, 70);
        $('#index-circle-ani .circle-bg-img.circle1').css({top: t1+'%',right: r1+'%'})
        $('#index-circle-ani .circle-bg-img.circle2').css({top: t2+'%',right: r2+'%'})
      }
    });
    
    var element = document.getElementById('index-circle-ani');
    mo.observe(element, {'attributes': true});
  }
})

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if (scroH > 10) {
    $('.circle-bg .top-bg').addClass('is-inview')
    $('.section2').addClass('is-inview')
    $('.scroll-icon').addClass('hide')
  }else{
    $('.circle-bg .top-bg').removeClass('is-inview')
    $('.section2').removeClass('is-inview')
    $('.scroll-icon').removeClass('hide')
  }
});


//banner轮播
var interleaveOffset = 0.5; //视差比值
var bannerswiper = new Swiper(".section1 .mySwiper", {
  speed: 800,
  watchSlidesProgress: true,
  watchOverflow: true,
  autoplay: {
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {// 分页
    el: ".section1 .swiper-pagination",
    clickable: true,
  },
  on:{
    progress: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			var slideProgress = swiper.slides[i].progress;
			var innerOffset = swiper.width * interleaveOffset;
			var innerTranslate = slideProgress * innerOffset;
			swiper.slides[i].querySelector(".slide-inner").style.transform =
			  "translate3d(" + innerTranslate + "px, 0, 0)";
		  }      
		},
		touchStart: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = "";
		  }
		},
		setTransition: function(swiper, speed) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = speed + "ms";
			swiper.slides[i].querySelector(".slide-inner").style.transition =
			  speed + "ms";
		  }
		}
  },
})

var sec2swiper_tab = new Swiper(".section2 .cont1 .mySwiper", {
  speed: 1000,
  slidesPerView: 2,
  slidesPerGroup : 2,
  spaceBetween: 10,
  breakpoints: { //响应式
    768: {  //当屏幕宽度大于等于768 
      slidesPerView: 3,
      slidesPerGroup : 3,
      spaceBetween: 15
    },
    1024: {  //当屏幕宽度大于等于768 
      slidesPerView: 4,
      slidesPerGroup : 4,
      spaceBetween: 15
    },
    1280: {  //当屏幕宽度大于等于1280
      slidesPerView: 5,
      slidesPerGroup : 5,
      spaceBetween: 25
    }
  },
  navigation: {//按钮
    nextEl: ".section2 .cont1 .swiper-button-next",
    prevEl: ".section2 .cont1 .swiper-button-prev",
  },
})

var sec2swiper_cont = new Swiper(".section2 .cont2 .mySwiper", {
  // autoHeight: true,
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  allowTouchMove: false,
  on:{
    init: function(swiper){
      $('.section2 .cont1 .mySwiper .swiper-slide').eq(0).addClass('active')
    },
    slideChangeTransitionStart: function(){
      $('.section2 .cont1 .mySwiper .swiper-slide').eq(this.realIndex).addClass('active').siblings().removeClass('active')
    },
  }
})

$('.section2 .cont1 .mySwiper .swiper-slide').hover(function(){
  let index = $(this).index()
  sec2swiper_cont.slideTo(index)
})

var sec4swipe = new Swiper(".section4 .mySwiper", {
  speed: 600,
  slidesPerView: 1.4,
  slidesPerGroup : 1,
  spaceBetween: 10,
  breakpoints: { //响应式
    768: {  //当屏幕宽度大于等于768 
      speed: 800,
      slidesPerView: 3.5,
      slidesPerGroup : 3,
      spaceBetween: 15
    },
    1024: {  //当屏幕宽度大于等于768 
      speed: 800,
      slidesPerView: 4.2,
      slidesPerGroup : 4,
      spaceBetween: 15
    },
    1280: {  //当屏幕宽度大于等于1280
      speed: 800,
      slidesPerView: 4.6,
      slidesPerGroup : 4,
      spaceBetween: 30
    }
  },
  navigation: {//按钮
    nextEl: ".section4 .swiper-button-next",
    prevEl: ".section4 .swiper-button-prev",
  },
})

// 生成随机数
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
